import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    {data.allStrapiTouchscreens.nodes.map(touchscreen => (
      <li><Link to={"/touchscreens/" + touchscreen.TouchscreenID}>{touchscreen.Title}</Link></li>
    ))}
  </Layout>
)

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiTouchscreens(sort: {fields: Title, order: ASC}) {
      nodes {
        Title
        TouchscreenID
      }
    }
  }
`

export default IndexPage
